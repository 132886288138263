import { useStdCart } from 'storefront-modules/cart'
import { useCallback } from 'react'
import { useGoogleAnalytics } from 'ui/lib/third-party'
import { CartItem } from 'ecosystem'

export const useCustomCart = () => {
  const GA = useGoogleAnalytics()

  const onAddToCart = useCallback(
    (cartItem: CartItem, cartTotal: number) => {
      GA.updateCart('add', cartTotal, [
        {
          ...cartItem,
          qty: String(cartItem.qty || 1)
        }
      ])
    },
    [GA]
  )

  const onRemoveFromCart = useCallback(
    (cartItem: CartItem, cartTotal: number) => {
      GA.updateCart('remove', cartTotal, [cartItem])
    },
    [GA]
  )

  return useStdCart({ onAddToCart, onRemoveFromCart })
}
